.GC22{
    background-color: #15011a;
    color: #fddc70;
}

.GC22topBorder{
    border-image: linear-gradient(#000000, #15011a) 100;
    border-top-width: 0px;
    border-bottom-width: 50px;
    border-style: solid;
}

.GC22 h1{
    font-family: 'Orbitron', sans-serif;
    font-weight: 500;
    font-size: 64px;
    text-align: center;
    color:#000000;
    -webkit-text-stroke-color: #fddc70;
    -webkit-text-stroke-width: 3px;
}

.GC22 p{
    font-size: 18px;
    color: #fddc70;
    margin-left: 15vw;
    margin-right:15vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.GC22 a{
    color:#fff1c4
}

.GC22 a:visited{
    color: #fd9870;
}

.GC22 a:hover{
    color: #c7fd70;
}

.GC2pic{
    display: inline-flex;
}

.GCpic1o2{
    border: #fddc70 2.5px solid;
    border-radius: 16px;
    margin-left: 5vw;
}

.GCpic1o2 img{
    border-radius: 16px;
    width: 42.5vw
}

.GC1pic{
    border: #fddc70 2.5px solid;
    border-radius: 16px;
    width: fit-content;
    margin-left: 25vw;
}

.GC1pic img{
    border-radius: 16px;
    width: 50vw
}

.GCslide{
    border: #fddc70 2.5px solid;
    border-radius: 16px;
    max-width: 50.2vw;
    width: fit-content;
    margin-left: 25vw;
}

.GCslide img{
    border-radius: 16px;
    width: 50vw
}

.GCslideTxt{
    background: rgba(0 , 0, 0, 0.7);
    border-radius: 16px;
    height: 90px;
    overflow-x: hidden;
    overflow-y: auto;
  scrollbar-width: thin;
}

.GCslideTxt::-webkit-scrollbar{
    width: 10px;
}

.GCslideTxt::-webkit-scrollbar-track {
    background: rgba(0 , 0, 0, 0.7);
    border-radius: 16px;
}

.GCslideTxt::-webkit-scrollbar-thumb {
    background: rgba(253, 220, 112, 0.7);
    border-radius: 16px;
}

.GCslideTxt::-webkit-scrollbar-thumb:hover {
    background: rgba(253, 152, 112, 0.7);
}

.GCslideTxt p{
    margin: 3px;
}

.GC22videoWrapper{
    width: fit-content;
    margin-left: 25vw;
    border: #fddc70 2.5px solid;
    margin-bottom: 2.5vh;
}

.GC22bottomBorder{
    border-image: linear-gradient(#15011a,  #fddc70) 100;
    border-top-width: 0px;
    border-bottom-width: 50px;
    border-style: solid;
}