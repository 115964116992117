.MarketBody{
    text-align: center;
    background-color: black;
    font-family: 'Russo One';
    color: #42FF00;
}

.MarketBorderBottom{
    border-image: linear-gradient( #4d9f0c, #000000) 100;
    border-top-width: 0px;
    border-bottom-width: 50px;
    border-style: solid;
}

.MarketBorderTop{
    border-image: linear-gradient(#000000, #4d9f0c) 100;
    border-top-width: 0px;
    border-bottom-width: 50px;
    border-style: solid;
}

.MarketBody h1{
    padding-top: 1vh;
}

.MarketBody p{
    padding-left: 2.5vw;
    padding-right: 2.5vw;
}

.MarketItem{
    background-color: rgba(44, 44, 44, .7);
    border: #42FF00 2px solid;
    border-radius: 16px;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

.MarketItem img{
    height: 75vh;
}

.SlideTB{
    background: rgba(0 , 0, 0, 0.7);
    border-radius: 16px;
}

.SlideTB h2, p{
    color: #42FF00;
}
